<template>
  <div class="calendar_main">
    <h1>{{ msg }}</h1>
    <p>
      Planifie tes entrainements en cliquant sur l'un des jours :
    </p>

    <table>
      <tr class="meteoTitle">
        <td></td>
        <td v-for="date in getDateWeather" :key="date">
          {{ date }}
        </td>
      </tr>
      <tr>
        <td>Charmey</td>
        <td v-for="weather in getCharmeyWeather" :key="weather">
          {{ weather }}
        </td>
      </tr>
      <tr>
        <td>Sanetsch</td>
        <td v-for="weather in getSanetschWeather" :key="weather">
          {{ weather }}
        </td>
      </tr>
      <tr>
        <td>Vernayaz</td>
        <td v-for="weather in getVernayazWeather" :key="weather">
          {{ weather }}
        </td>
      </tr>
    </table>

    <div class="calendar">
      <ul class="month">
        <li class="prev" v-on:click="this.previous();">❮</li>
        <li id="monthName">{{month_name}} : {{year}}</li>
        <li class="next" v-on:click="this.next();">❯</li>
      </ul>
      <ul class="weekdays">
        <li class="big-name">Lundi</li>
        <li class="small-name">Lu</li>
        <li class="big-name">Mardi</li>
        <li class="small-name">Ma</li>
        <li class="big-name">Mercredi</li>
        <li class="small-name">Me</li>
        <li class="big-name">Jeudi</li>
        <li class="small-name">Je</li>
        <li class="big-name">Vendredi</li>
        <li class="small-name">Ve</li>
        <li class="big-name">Samedi</li>
        <li class="small-name">Sa</li>
        <li class="big-name">Dimanche</li>
        <li class="small-name">Di</li>
      </ul>
      <ul ref="days_list" class="days">
        <li v-for="date in dates" :key="date" @click="showModal = true; this.dayClicked(date.day);">
          {{ date.day }}
        </li>
      </ul>
    </div>
    <ActivityView v-show="showModal" @close-modal="showModal = false"/>
  </div>
</template>

<script>
import ActivityView from './ActivityView.vue'
import Utils from "@/js/utils";
import {VueElement} from "vue";

export default {
  name: 'CalendarView',
  components: {
    ActivityView
  },
  props: {
    msg: String
  },
  data() {
    return {
      month_name: "null",
      year: "0000",
      current_month: -1,
      dates: [],
      showModal: false,
      charmey: "https://www.prevision-meteo.ch/services/json/Charmey",
      sanetsch: "https://www.prevision-meteo.ch/services/json/Sanetsch",
      vernayaz: "https://www.prevision-meteo.ch/services/json/Vernayaz",
      dateWeather: [],
      charmeyWeather: [],
      sanetschWeather: [],
      vernayazWeather: [],
    }
  },
  methods: {
    initCalendar(date) {
      this.current_month = date.getMonth();
      this.year = date.getFullYear();
      this.month_name = Utils.getMonthName(this.current_month);
    },
    generateCalendar() {
      let month = this.current_month+1;
      let daysInMonth = new Date(this.year, month, 0).getDate();
      this.dates = [];
      for (let i = 1; i <= daysInMonth; i++) {
        this.dates.push({ day: i});
      }
      //dataStorage.getDayActivities();
    },
    async dayClicked(day) {
      //get current date
      let date = new Date(this.year, this.current_month, day);
      this.$store.commit('changeDate',date);
      this.$store.commit('changeDay',day);
      this.$store.commit('changeMonth',this.month_name);

      await ActivityView.methods.show(date);

      //no activities
      // -> add activity
      /*if (day.activities !== undefined) {
        this.showModal = true;
        //return;
      }*/

      //has activities
      // -> view activity
      // -> modify activity

    },
    previous() {
      this.current_month--;
      if (this.current_month === -1) {
        this.current_month = 11;
        this.year--;
      }
      this.changeMonth(this.current_month);
    },
    next() {
      this.current_month++;
      this.current_month = (this.current_month % 12);
      if (this.current_month === 0) {
        this.year++;
      }
      this.changeMonth(this.current_month);
    },
    changeMonth(monthId) {
      let newDate = new Date(this.year,monthId);
      this.month_name = Utils.getMonthName(monthId);
      this.generateCalendar(newDate);
    },
    loadMeteoCharmey() {
      let request = new XMLHttpRequest();
      let pointer = this;

      request.onreadystatechange =  function() {
        if (request.readyState == XMLHttpRequest.DONE) {
          pointer.dateWeather = [];
          pointer.dateWeather[0] = request.response.fcst_day_0.date;
          pointer.dateWeather[1] = request.response.fcst_day_1.date;
          pointer.dateWeather[2] = request.response.fcst_day_2.date;
          pointer.dateWeather[3] = request.response.fcst_day_3.date;
          pointer.dateWeather[4] = request.response.fcst_day_4.date;

          pointer.charmeyWeather = [];
          pointer.charmeyWeather.push(request.response.fcst_day_0.condition);
          pointer.charmeyWeather.push(request.response.fcst_day_1.condition);
          pointer.charmeyWeather.push(request.response.fcst_day_2.condition);
          pointer.charmeyWeather.push(request.response.fcst_day_3.condition);
          pointer.charmeyWeather.push(request.response.fcst_day_4.condition);
        }
      }
      request.open('GET', this.charmey);
      request.responseType = 'json';
      request.send();
    },
    loadMeteoSanetsch() {
      let request = new XMLHttpRequest();
      let pointer = this;

      request.onreadystatechange = function() {
        if (request.readyState == XMLHttpRequest.DONE) {
          pointer.sanetschWeather = [];

          pointer.sanetschWeather.push(request.response.fcst_day_0.condition);
          pointer.sanetschWeather.push(request.response.fcst_day_1.condition);
          pointer.sanetschWeather.push(request.response.fcst_day_2.condition);
          pointer.sanetschWeather.push(request.response.fcst_day_3.condition);
          pointer.sanetschWeather.push(request.response.fcst_day_4.condition);
        }
      }
      request.open('GET', this.sanetsch);
      request.responseType = 'json';
      request.send();
    },
    loadMeteoVernayaz() {
      let request = new XMLHttpRequest();
      let pointer = this;

      request.onreadystatechange = function() {
        if (request.readyState == XMLHttpRequest.DONE) {
          pointer.vernayazWeather = [];

          pointer.vernayazWeather.push(request.response.fcst_day_0.condition);
          pointer.vernayazWeather.push(request.response.fcst_day_1.condition);
          pointer.vernayazWeather.push(request.response.fcst_day_2.condition);
          pointer.vernayazWeather.push(request.response.fcst_day_3.condition);
          pointer.vernayazWeather.push(request.response.fcst_day_4.condition);
        }
      }
      request.open('GET', this.vernayaz);
      request.responseType = 'json';
      request.send();
    },
  },
  computed: {
    getCharmeyWeather() {
      return this.charmeyWeather;
    },
    getSanetschWeather() {
      return this.sanetschWeather;
    },
    getVernayazWeather() {
      return this.vernayazWeather;
    },
    getDateWeather() {
      return this.dateWeather;
    },
  },
  beforeMount() {
    let now = new Date();
    VueElement.prototype.$date = now;
    this.initCalendar(now);
    this.generateCalendar();

  },
  async mounted() {
    await this.loadMeteoCharmey();
    await this.loadMeteoVernayaz();
    await this.loadMeteoSanetsch();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
* {box-sizing: border-box;}
ul {list-style-type: none;}
body {font-family: Verdana, sans-serif;}

.meteoTitle {
  background: #1abc9c;
  color: white;
  margin: 2%;
}

table {
  width: 80% !important;
}



.calendar {
  padding: 75px 25px;
  width: 100%;
}

.calendar ul {
  margin: 0;
  padding: 0;
}

.calendar ul li {
  font-size: 125%;
  letter-spacing: 3px;
}

.month {
  color: white;
  padding: 2.5% !important;
  width: 100%;
  text-align: center;
  background: #1abc9c;
}

.prev {
  display: inline;
  float: left;
  margin-left: 1%;
}

.next {
  display: inline;
  float: right;
  margin-right: 1%;
}

#monthName{
  display: inline;
}

/* Weekdays (Mon-Sun) */
.weekdays {
  margin: 0;
  padding: 1% 0.1% !important;
  background-color: #ddd;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.weekdays li {
  flex: 1 0 14.2%;
  max-width: 14%;
  width: 13.6%;
  color: #666 !important;
  text-align: center;
  padding-top: 0.1% !important;
}

/* Days (1-31) */
.days {
  padding: 0.5% 0 !important;
  background: #eee;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.days li {
  flex: 1 0 14.2%;
  list-style-type: none;
  max-width: 14%;
  width: 13.6%;
  margin-bottom: 0.5%;
  font-size:1.2%;
  color: #777;
}

.days li .active {
  padding: 0.5%;
  background: #1abc9c;
  color: white !important
}

.small-name {
  display: none;
}

/* Add media queries for smaller screens */
@media screen and (max-width:800px) {
  .weekdays li, .days li {width: 13.1%;}
  .calendar ul li {
    font-size: 100%;
  }
  table {
    font-size: small;
  }
}

@media screen and (max-width: 680px) {
  .weekdays li, .days li {width: 12.5%;}
  .days li .active {padding: 0.2%;}
  .calendar {
    padding: 65px 15px;
  }
  .small-name {
    display: block;
  }
  .big-name {
    display: none;
  }
  table {
    font-size: x-small;
  }
}

@media screen and (max-width: 400px) {
  .weekdays li, .days li {width: 12.2%;}
  .calendar {
    padding: 55px 5px;
  }
  .calendar ul li {
    font-size: 90%;
  }
  .small-name {
    display: block;
  }
  .big-name {
    display: none;
  }
  table {
    font-size: xx-small;
  }
}
</style>
