import { createApp } from 'vue'
import App from './App.vue'

import { createStore } from 'vuex'
import './registerServiceWorker'

// Create a new store instance.
const store = createStore({
    state () {
        return {
            date: "",
            month: "",
            day: "",
        }
    },
    mutations: {
        changeDate (state,date) {
            state.date = date;
        },
        changeDay (state,day) {
            state.day = day;
        },
        changeMonth (state,month) {
            state.month = month;
        }
    }
})

const app = createApp(App);

// Install the store instance as a plugin
app.use(store)

app.mount('#app')
