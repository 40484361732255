const DB_NAME = 'trainingdb';
const DB_VERSION = 1;
let DB;

export default {
    async getDb() {
        return new Promise((resolve, reject) => {

            if(DB) { return resolve(DB); }
            console.log('OPENING DB', DB);
            let request = window.indexedDB.open(DB_NAME, DB_VERSION);

            request.onerror = e => {
                console.log('Error opening db', e);
                reject('Error');
            };

            request.onsuccess = e => {
                DB = e.target.result;
                resolve(DB);
            };

            request.onupgradeneeded = e => {
                console.log('onupgradeneeded');
                let db = e.target.result;
                //autoincrement must be false in order to put custom key !!
                db.createObjectStore("trainingActivities", { autoIncrement: false });
            };
        });
    },
    async getDayActivities(time) {

        let db = await this.getDb();

        return new Promise(resolve => {
            let trans = db.transaction('trainingActivities','readonly');
            trans.oncomplete = () => {
                resolve(result);
            };
            let store = trans.objectStore('trainingActivities');
            let result = [];

            store.openCursor().onsuccess = function(event) {
                let cursor = event.target.result;

                if (cursor && (cursor.key.getTime() === time.getTime())) {
                    result = cursor.value;
                }
            };
        });


    },

    async saveActivities(activityTab,timeKey) {
        let db = await this.getDb();
        return new Promise(resolve => {

            let trans = db.transaction('trainingActivities','readwrite');
            trans.oncomplete = () => {
                resolve();
            };

            let store = trans.objectStore('trainingActivities');
            let data = JSON.parse(JSON.stringify(activityTab));
            store.put(data,timeKey);
        });

    }

}