<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <h6>Activités {{this.$store.state.day}} {{this.$store.state.month}} </h6>
      <table>
        <tr>
          <td>Activité</td>
          <td>Temps</td>
          <td>Remarque</td>
        </tr>
        <tr v-for="data in getNewData" :key="data">
          <td v-if="data.date.getTime() === this.$store.state.date.getTime()">{{data.activity}}</td>
          <td v-if="data.date.getTime() === this.$store.state.date.getTime()">{{data.time}}</td>
          <td v-if="data.date.getTime() === this.$store.state.date.getTime()">{{data.note}}</td>
        </tr>
        <tr>
          <td><input v-model="activityName"></td>
          <td><input v-model="time"></td>
          <td><input v-model="note"></td>
        </tr>
      </table>
      <button v-on:click="this.save();">Sauvegarder</button>
    </div>
  </div>
</template>

<script>
import dataStorage from "@/js/data-storage";

export default {
  name: "ActivityView",
  data() {
    return {
      activityName: "",
      time: "",
      note: "",
      newData: [],
    }
  },
  methods: {
    async save() {
      this.newData.push({date: this.$store.state.date, activity: this.activityName, time: this.time, note: this.note});

      await dataStorage.saveActivities(this.newData, this.$store.state.date);
    },
    async show(date) {
      await dataStorage.getDayActivities(date).then(function(result) {
        console.log("Showing result :");
        console.log(result);
        if (result) {
          this.newData = result;
        }
      }.bind(this));
      console.log("Showing new data :");
      console.log(this.newData);
    },
  },
  computed: {
    getNewData() {
      return this.newData;
    },
  }
}
</script>

<style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #000000da;
  }

  .modal {
    text-align: center;
    background-color: white;
    height: 500px;
    width: 500px;
    margin-top: 10%;
    padding: 60px 0;
    border-radius: 20px;
  }
  @media screen and (max-width: 600px) {
    .modal {
      text-align: center;
      background-color: white;
      height: 400px;
      width: 300px;
      margin-top: 10%;
      padding: 40px 0;
      border-radius: 15px;
    }
  }
  .close {
    margin: 10% 0 0 16px;
    cursor: pointer;
  }

  .check {
    width: 150px;
  }

  h6 {
    font-weight: 500;
    font-size: 28px;
    margin: 20px 0;
  }

  p {
    font-size: 16px;
    margin: 20px 0;
  }

  button {
    background-color: #1abc9c;
    width: 150px;
    height: 40px;
    color: white;
    font-size: 14px;
    margin-top: 50px;
    border: 0;
  }

  input {
    width: 80%;
  }
</style>